import React, { lazy, Suspense } from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'

const StartPage = lazy(() => import('../common/pages/StartPage'))
const Register = lazy(() => import('../common/pages/Register'))
const LeaderBoard = lazy(() => import('../common/pages/LeaderBoard'))
const Game = lazy(() => import('../common/pages/Game'))
const Test = lazy(() => import('./test'))

const MainRoutes = () => {
  return (
    <Suspense>
      <Routes>
        <Route path='/' element={<Navigate to='/start' />} />
        <Route path='start' element={<StartPage />} />
        <Route path='register' element={<Register />} />
        <Route path='leaderboard' element={<LeaderBoard />} />
        <Route path='game' element={<Game />} />
        <Route path='test' element={<Test />} />
      </Routes>
    </Suspense>
  )
}

export default MainRoutes
