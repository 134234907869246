import React, { createContext, useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { useTimer, useLifes, useScore } from '../hooks'

const BoardContext = createContext()

const INITIAL_BOARD = [
  { component: null, type: null },
  { component: null, type: null },
  { component: null, type: null },
  { component: null, type: null },
  { component: null, type: null },
  { component: null, type: null }
]

const BoardProvider = ({ children }) => {
  const [board, setBoard] = useState(INITIAL_BOARD)

  const { start, thents, seconds } = useTimer()
  const { setLifes } = useLifes()
  const { setScore, level } = useScore()

  useEffect(() => {
    if (thents % level.spawn_frequency === 0) {
      spawn()
    }
  }, [thents])

  useEffect(() => {
    const swappeds = board.filter(mole => mole.swapped === true)
    if (level.difficulty >= 2 && !swappeds.length) {
      swap()
    }
  }, [seconds])

  const initGame = (clear = true) => {
    if (clear) localStorage.clear()
    reset()
    start()
    setLifes(3)
    setScore(0)
  }

  const reset = () => {
    setBoard(INITIAL_BOARD)
  }

  const removeFromIndex = index => {
    const tmp_board = [...board]
    tmp_board[index] = { component: null, type: null }
    setBoard(tmp_board)
  }

  const pickMole = (index, good) => {
    const good_moles = [
      {
        type: 'good',
        component: 'Money',
        index
      },
      {
        type: 'good',
        component: 'Bags',
        index
      },
      {
        type: 'good',
        component: 'Stonks',
        index
      }
    ]
    const bad_moles = [
      {
        type: 'bad',
        component: 'Corona',
        index
      },
      {
        type: 'bad',
        component: 'Time',
        index
      },
      {
        type: 'bad',
        component: 'NotStonks',
        index
      }
    ]

    let random = Math.floor(Math.random() * 3)
    if (!good) return bad_moles[random]

    random = Math.floor(Math.random() * 10)
    if (random <= 4) return good_moles[0]
    return random > 7 ? good_moles[2] : good_moles[1]
  }

  const spawn = () => {
    const tmp_board = [...board]
    let randomPosition = Math.floor(Math.random() * 6)

    const empty_spaces = tmp_board.filter(element => element.component === null).length
    const filled_spaces = tmp_board.filter(element => element.component !== null).length

    if (filled_spaces >= level.max_spawn) return

    if (empty_spaces) {
      while (tmp_board[randomPosition].component !== null) {
        randomPosition = Math.floor(Math.random() * 6)
      }
    }

    let good = Math.random() < level.good_chance

    const goods = tmp_board.filter(element => element.type === 'good')
    const bads = tmp_board.filter(element => element.type === 'bad')

    if (goods.length >= 3) good = false

    if (bads.length >= 3) good = true

    const selected = pickMole(randomPosition, good)

    tmp_board[randomPosition] = selected

    setBoard(tmp_board)
  }

  const swap = () => {
    const tmp_board = [...board]

    const findIndexes = (array, property) => {
      const indexes = []
      for (let i = 0; i < array.length; i++) {
        if (array[i].type === property) {
          indexes.push(i)
        }
      }
      return indexes
    }

    const good_indexes = findIndexes(tmp_board, 'good')
    const bad_indexes = findIndexes(tmp_board, 'bad')

    if (good_indexes.length && bad_indexes.length) {
      const random_good = Math.floor(Math.random() * good_indexes.length)
      const random_bad = Math.floor(Math.random() * bad_indexes.length)

      const good_index = good_indexes[random_good]
      const bad_index = bad_indexes[random_bad]

      const temp = { ...tmp_board[good_index], swapped: true, index: bad_index }
      tmp_board[good_index] = { ...tmp_board[bad_index], swapped: true, index: good_index }
      tmp_board[bad_index] = temp

      setBoard(tmp_board)
    }
  }

  return (
    <BoardContext.Provider value={{ board, setBoard, removeFromIndex, reset, initGame, spawn, swap }}>
      {children}
    </BoardContext.Provider>
  )
}

BoardProvider.propTypes = {
  children: propTypes.any
}

export { BoardContext, BoardProvider }
