import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { useScore } from '../hooks'

const Theme = ({ children }) => {
  const { inverted } = useScore()
  const [theme, setTheme] = useState({
    fill: '#ff4438'
  })

  useEffect(() => {
    setTheme({
      fill: inverted ? 'white' : '#ff4438'
    })
  }, [inverted])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export { Theme }
