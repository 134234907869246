import React, { createContext, useState } from 'react'
import propTypes from 'prop-types'

const LifesContext = createContext()

const LifesProvider = ({ children }) => {
  const [lifes, setLifes] = useState(3)

  const decrease = () => {
    const tmp_lifes = lifes
    if (tmp_lifes > 0) setLifes(tmp_lifes - 1)
  }

  return <LifesContext.Provider value={{ lifes, decrease, setLifes }}>{children}</LifesContext.Provider>
}

LifesProvider.propTypes = {
  children: propTypes.any
}

export { LifesContext, LifesProvider }
