import React, { createContext, useState, useEffect } from 'react'
import { useTimer } from '../hooks'
import propTypes from 'prop-types'

const ScoreContext = createContext()

const LEVELS = [
  {
    difficulty: 0,
    spawn_frequency: 10,
    good_duration: 20,
    animation: '2.0s',
    max_spawn: 2,
    good_chance: 0.8,
    music_velocity: 1
  },
  {
    difficulty: 1,
    spawn_frequency: 8,
    good_duration: 20,
    animation: '2.0s',
    max_spawn: 3,
    good_chance: 0.7,
    music_velocity: 1.1
  },
  {
    difficulty: 2,
    spawn_frequency: 7,
    animation: '1.7s',
    good_duration: 17,
    max_spawn: 4,
    good_chance: 0.6,
    music_velocity: 1.2
  },
  {
    difficulty: 3,
    spawn_frequency: 5,
    animation: '1.5s',
    good_duration: 15,
    max_spawn: 5,
    good_chance: 0.5,
    music_velocity: 1.3
  },
  {
    difficulty: 4,
    spawn_frequency: 3,
    animation: '1.2s',
    good_duration: 12,
    max_spawn: 6,
    good_chance: 0.5,
    music_velocity: 1.4
  }
]

const ScoreProvider = ({ children }) => {
  const [score, setScore] = useState(0)
  const [level, setLevel] = useState(LEVELS[0])
  const [inverted, setInverted] = useState(true)
  const [shake, setShake] = useState(false)

  const { time } = useTimer()

  const increase = points_to_add => {
    setScore(score + points_to_add)
  }

  useEffect(() => {
    if (score < 1500) {
      setLevel(LEVELS[0])
    } else if (score >= 1500 && score < 3000) {
      setLevel(LEVELS[1])
    } else if (score >= 3000 && score < 4500) {
      setLevel(LEVELS[2])
    } else if (score >= 4500 && score < 6000) {
      setLevel(LEVELS[3])
    } else {
      setLevel(LEVELS[4])
    }
  }, [score])

  useEffect(() => {
    if (score >= 6000 && time > 0 && time % 50 === 0) {
      setInverted(!inverted)
    }
    setShake(false)
  }, [time])

  return (
    <ScoreContext.Provider value={{ level, score, increase, setScore, inverted, shake, setShake, setInverted }}>
      {children}
    </ScoreContext.Provider>
  )
}

ScoreProvider.propTypes = {
  children: propTypes.any
}

export { ScoreContext, ScoreProvider }
