import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import MainRoutes from './routes'
import './app.css'
import { Helmet } from 'react-helmet'

import { TimerProvider, ScoreProvider, LifesProvider, BoardProvider, Theme } from './common/providers'

function App() {
  return (
    <>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' />
      </Helmet>
      <LifesProvider>
        <TimerProvider>
          <ScoreProvider>
            <BoardProvider>
              <Theme>
                <BrowserRouter>
                  <MainRoutes />
                </BrowserRouter>
              </Theme>
            </BoardProvider>
          </ScoreProvider>
        </TimerProvider>
      </LifesProvider>
    </>
  )
}

export default App
