import React, { createContext, useState, useEffect } from 'react'
import propTypes from 'prop-types'

const TimerContext = createContext()

const TimerProvider = ({ children }) => {
  const [seconds, setSeconds] = useState(60)
  const [thents, setThents] = useState(600)
  const [time, setTime] = useState(0)

  const addTime = thents_to_add => {
    setSeconds(seconds + thents_to_add / 10)
    setThents(thents + thents_to_add)
  }

  const stopTimer = () => {
    setSeconds(0)
    setThents(0)
    setTime(0)
  }

  const start = () => {
    setSeconds(60)
    setThents(600)
  }

  useEffect(() => {
    let interval = setInterval(() => {
      if (seconds > 0) {
        setThents(thents - 1)
        if (thents % 10 === 0) {
          setSeconds(seconds - 1)
        }
        setTime(time + 1)
      }
    }, 100)
    return () => {
      clearInterval(interval)
    }
  })

  return (
    <TimerContext.Provider value={{ thents, seconds, addTime, stopTimer, start, time }}>
      {children}
    </TimerContext.Provider>
  )
}

TimerProvider.propTypes = {
  children: propTypes.any
}

export { TimerContext, TimerProvider }
